html,
body {
  margin: 0;
  font-family: Helvetica, Arial, sans-serif;
  background: #eee;
}

main,
header,
footer {
  padding: 8px;
}

header {
  border-bottom: 1px solid darkgray;
  background: #eee;
}

footer {
  border-top: 1px solid darkgray;
  display: flex;
  flex-direction: column;
}

.menu {
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  padding: 0;
  margin: 0;
  list-style: none;
  justify-content: flex-end;
}

.menu-link {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 16px;
  border: 1px solid lightgray;
  border-radius: 8px;
}

.menu-link:hover {
  background: lightgray;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

svg {
 width: inherit;
 height: inherit;
}

main {
  margin: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-button {
  background: transparent;
  border: 0;
  padding: 0;
  color: black;
  display: flex;
  cursor: pointer;
  width: 32px;
  height: 32px;
}

td {
   padding: 8px;
}

.grid-div {
  display: grid;
  gap: 10px;
  width: 100%;
}

.my-flex-box {
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
}

.income {
  color: blue;
}

.payment {
  color: red;
}

.italic {
  font-style: italic;
}

.plain {
  font-style: normal;
}

input[type="text"]
{
    background: transparent;
    border: none;
}

input[type="date"]
{
    width: 90px;
    font-size: smaller;
}

input, select, textarea, button
{
  background: transparent;
  font-family: inherit;
  font-style: inherit;
  color: inherit;
  width: 100%;
  border: none;
}

td {
  padding: 1px;
}

.col_1
{
  width:15%;
  min-width:80px;
}

.col_2
{
  width:15%;
  min-width:80px;
}

.col_3
{
  width:55%;
  min-width:40px;
}

.col_4
{
  width:15%;
  min-width:30px;
}
